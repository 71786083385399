import * as React from 'react'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import loadable from '@loadable/component'
import {
  FaArrowRight,
  FaFacebook,
  FaInstagram,
  FaEnvelope,
} from 'react-icons/fa'
import config from '../components/config'
import { MainImage } from './image'
import { Loader } from './loader'
import { GrayBox, DefaultContainer } from './containers'
import { LeftIcon } from './button-icon'

const ContactForm = loadable<any>(() => {
  return new Promise(r => {
    setTimeout(
      () => r(import('./contact')),
      process.env.NODE_ENV !== 'production' ? 0 : 2000,
    )
  })
})

interface SectionOneProps extends React.HTMLProps<HTMLDivElement> {
  pageTitle: React.ReactNode
  description: React.ReactNode
  image: React.ReactNode
}

export const SectionOne: React.FC<SectionOneProps> = ({
  pageTitle,
  description,
  image,
  ...props
}) => {
  return (
    <GrayBox {...props}>
      <DefaultContainer>
        <div className={'block md:flex items-center justify-between'}>
          <div className={'mr-0 md:mr-10 mb-10 md:mb-2.5 spacing-y-3.5'}>
            <h2
              className={'mb-4 text-3xl text-center md:text-left font-medium'}
            >
              {pageTitle}
            </h2>
            <div className={'leading-7 mb-4'}>{description}</div>
            <div className={'flex md:block flex-col text-center md:text-left'}>
              <a href={'#contacto'} className={'shadow-xl btn btn-blue'}>
                Contactanos
              </a>
            </div>
          </div>
          <div
            className={'relative hidden md:block max-w-1/2'}
            style={{ minWidth: '28rem' }}
          >
            {image}
          </div>
        </div>
      </DefaultContainer>
    </GrayBox>
  )
}

interface SectionTwoProps extends React.HTMLProps<HTMLDivElement> {
  pageTitle: React.ReactNode
  description: React.ReactNode
  image: React.ReactNode
}

export const SectionTwo: React.FC<SectionTwoProps> = ({
  pageTitle,
  description,
  image,
  ...props
}) => {
  return (
    <section className={'py-20 bg-white border-b border-gray-200'} {...props}>
      <DefaultContainer>
        <div className={'md:grid grid-cols-5 gap-4'}>
          <div className={'hidden md:block col-span-2'}>{image}</div>
          <div className={'flex flex-col col-span-3 ml-0 md:ml-8 space-y-3.5'}>
            <h2
              className={'mb-1.5 text-2xl font-medium text-center md:text-left'}
            >
              {pageTitle}
            </h2>
            {description}
          </div>
        </div>
      </DefaultContainer>
    </section>
  )
}

interface ContactProps extends React.HTMLProps<HTMLDivElement> {
  business: string
  businessName: string
  priceRange: string
}

export const Contact: React.FC<ContactProps> = ({
  business,
  businessName,
  priceRange,
  ...props
}) => {
  return (
    <DefaultContainer id={'contacto'}>
      <section className={'block md:flex justify-between py-20'}>
        <div className={'w-full mr-0 md:mr-14'}>
          <h2 className={'text-3xl font-regular mb-2.5'}>Contactanos</h2>
          <p className={'mb-5'}>
            Si tienes alguna duda, sugerencia, queja o reclamo no dudes en
            contactarnos.
          </p>

          <ContactForm
            spacing={'7px'}
            fallback={
              <div className={'relative'} style={{ height: '422px' }}>
                <Loader />
              </div>
            }
          />

          <h3 className={'my-5 text-3xl'}>Redes sociales</h3>
          <div
            className={
              'flex flex-col md:flex-row space-y-3.5 md:space-y-0 md:space-x-3.5'
            }
          >
            <a
              className={'btn btn-blue'}
              href={config.social.facebook}
              target={'_blank'}
              rel={'noopener noreferrer nofollow'}
              onClick={() => {
                if (typeof window !== 'undefined') {
                  window.ga?.(
                    'send',
                    'social',
                    'Facebook',
                    'Visit',
                    config.social.facebook,
                  )
                }
              }}
            >
              <LeftIcon>
                <FaFacebook />
              </LeftIcon>
              Facebook
            </a>
            <a
              className={'btn btn-blue'}
              href={config.social.instagram}
              rel={'noopener noreferrer nofollow'}
              target={'_blank'}
              onClick={() => {
                if (typeof window !== 'undefined') {
                  window.ga?.(
                    'send',
                    'social',
                    'Instagram',
                    'Visit',
                    config.social.facebook,
                  )
                }
              }}
            >
              <LeftIcon>
                <FaInstagram />
              </LeftIcon>
              Instagram
            </a>
            <a
              className={'btn btn-green'}
              href={`mailto:${config.contact.email}`}
              onClick={() => {
                trackCustomEvent({
                  category: 'button',
                  action: 'click',
                  label: `mail`,
                  // nonInteraction: false,
                  // transport: 'beacon',
                })
              }}
            >
              <LeftIcon>
                <FaEnvelope />
              </LeftIcon>
              {config.contact.email}
            </a>
          </div>
        </div>

        <div className={'mt-6 md:mt-0'}>
          <h3 className={'text-3xl font-regular'}>Cómo contactarnos</h3>
          <p>{businessName} Domicilios 24/7</p>

          <figure>
            <MainImage
              alt={`${businessName} 24/7`}
              reference={'logo'}
              className={'mt-3.5'}
              style={{ width: '200px' }}
            />
          </figure>

          <TText>Teléfono</TText>
          <p>{config.contact.extendedPhoneNumbers.join(' - ')}</p>

          <TText>Correo electrónico</TText>
          <p>
            <a href={`mailto:${config.contact.email}`}>
              {config.contact.email}
            </a>
          </p>

          <TText>Cobertura</TText>
          <p>Toda Bogotá.</p>

          <TText>Horarios de atención</TText>
          <p>{config.main.schedules.text}.</p>

          <TText>Métodos de pago</TText>
          <p>{config.main.paymentAccepted.join(', ')}.</p>

          <TText>Rango de precios</TText>
          <p>{priceRange}</p>
        </div>
      </section>
    </DefaultContainer>
  )
}

const TText: React.FC = ({ children }) => {
  return <p className={'font-bold'}>{children}</p>
}

export const Option: React.FC = ({ children }) => {
  return (
    <p>
      <FaArrowRight className={'text-blue-500 inline-block align-middle'} />{' '}
      <span role="listitem">{children}</span>
    </p>
  )
}
