import * as React from 'react'
import { PageProps } from 'gatsby'
import { Contact } from '../../components/page'
import { Layout } from '../../components/default-layout'
import { SEO } from '../../components/seo'
import { SpecificLogo } from '../../components/logo'
import { Slider } from '../../components/slider'
import { DefaultContainer } from '../../components/containers'

export const Head = () => {
  return (
    <SEO
      article={false}
      title={'Programación de llaves con chip'}
      description={
        'Programamos llaves con chip de cualquier marca de vehículos: BMW, Audi, Mercedes Benz, Nissan, Jeep, etc'
      }
    />
  )
}

const Text: React.FC<{ children: React.ReactNode | string }> = ({
  children,
}) => <p>{children}</p>

const Locksmith: React.FC<PageProps> = () => {
  return (
    <Layout
      theme={'yellow'}
      visible={{}}
      logo={<SpecificLogo>Cerrajería</SpecificLogo>}
    >
      <Slider
        id={'inicio'}
        overlay={0.8}
        business={'Cerrajero profesional'}
        wallpaper={'keysWithChip'}
        pageTitle={'Programación de llaves con chip'}
        subTitle={'Cerrajero las 24 horas del día'}
        pageDescription={
          <>
            En caso de pérdida o desgaste de la llave de su vehículo, Domicilios
            24/7 presta un servicio de <strong>cerrajería automotriz</strong> de
            alta calidad, único en Bogotá; el{' '}
            <strong>duplicado de llave con chip</strong> no daña ningún
            componente de su vehículo y es 100% seguro.
          </>
        }
        goTo={'#servicios'}
      />

      <DefaultContainer>
        <section id="servicios" className={'my-12'}>
          <H2>Programación de llaves con chip</H2>
          <Text>
            Muchos fabricantes de vehículos utilizan llaves con transponder; más
            conocidas como llaves con chip; para agregar un nivel de seguridad a
            los vehículos, haciendo más difícil su hurto. Las llaves con chip
            están diseñadas con códigos digitales, lo que les permite
            comunicarse con vehículos que están equipados con el sistemas
            transponder. Cada llave con chip tiene un código digital único. Esto
            significa que el propietario debe tener la llave con chip correcta
            para poder encender el vehículo. Dados los avances en la tecnología,
            el reemplazo de llaves con chip es mucho más costoso y difícil que
            el reemplazo de otros tipos de llaves comunes. Examinaremos
            diferentes aspectos del duplicado de llaves con chip y le
            proporcionaremos los datos y detalles que necesita saber.
          </Text>
          <H2>Cómo obtener un duplicado de llaves con chip</H2>
          <Text>
            Los fabricantes de vehículos han estado pensando durante las últimas
            décadas en formas de prevenir el robo de vehículos, ya que en muchas
            ciudades del país se ha disparado el hurto de vehículos. Con todos
            los avances en tecnología, una de las formas de hacerlo más difícil
            es mediante el uso de un pequeño chip programable en la llave del
            vehículo. Estas llaves son relativamente nuevas y emiten un sello o
            firma electrónica que permite que el encendido del vehículo. Debido
            a que con cualquier innovación, surgen nuevos problemas; la cuestión
            de cómo duplicar este código es un problema para muchos.
          </Text>
          <Text>
            Técnicamente, la llave con chip no es una llave convencional; puede
            ser útil pensar en ella como un código de acceso. Al igual que cada
            código de acceso o contraseña que todos poseemos, existe un
            elementos que hacen que cada contraseña sea única. Piensa en una
            contraseña que creaste para tu correo electrónico. Lo más probable
            es que tu contraseña se componga de letras mayúsculas, minúsculas e
            incluso caracteres especiales. En este sentido todos estos elementos
            se organizan de una manera que sea difícil de acceder para otros;
            por privacidad y por seguridad.
          </Text>
          <Text>
            Al igual que una contraseña, cada llave codificada ofrece sus
            propios elementos o firmas específicas. Dado que tiene un código
            único, no se pueden copiar, como las llaves convencionales de un
            vehículo. Para que una llave con chip funcione, debe programar la
            llave para una frecuencia de vehículo específica, capaz de aceptar
            el código en el chip; sin embargo, hay pasos que puede seguir para
            hacer una copia de sus llaves.
          </Text>

          <H2>¿Cómo se pueden duplicar las llaves con chip?</H2>
          <Text>
            El siguiente es el listado de opciones con las cuales cuentas para
            generar un duplicado de tus llaves con chip.
          </Text>

          <H3>Obtenga la llave en línea</H3>
          <Text>
            Verifica si tu cerrajero o concesionario programan llaves, por
            razones de seguridad, algunos cerrajeros y concesionarios pueden
            optar por obtener o elaborar sus propias llaves. Este caso es más
            frecuente para los concesionarios. Puedes buscar en Internet y ver
            qué concesionarios ofrecen el mejor precio para tu llave. Ten en
            cuenta que una llave comprada en línea NO llegará a tu ubicación
            actual. Por lo tanto, si compras una llave con chip en línea, aún
            tendrás que llamar a tu cerrajero o concesionario local para
            programar la llave correctamente.
          </Text>

          <H3>Comunícate con tu concesionario</H3>
          <Text>
            Cuando se decide por un duplicado de llaves con chip de un
            concesionario, ten en cuenta que te costará más dinero. El
            concesionario podrá acceder a tus datos desde el número de placa de
            tu vehículo y procederá a crear una llave para ti. Ten en cuenta que
            el tiempo para recibir el duplicado puede variar. Es posible que el
            concesionario deba realizar algunas comprobaciones de seguridad
            antes de hacer una nueva llave con chip. Esta tarea puede llevar
            algunos días, o incluso algunas semanas. No olvides que deberás
            completar la documentación correspondiente para solicitar tu otra
            llave con chip.
          </Text>

          <H3>Llamar a un cerrajero</H3>
          <Text>
            Hoy en día, los cerrajeros dirigimos nuestro negocio, respondiendo a
            las tecnologías cambiantes. Atrás quedaron los días del cerrajero
            convencional que podía hacer una copia de las llaves de la casa y el
            vehículo en un instante. Hoy en día, muchos cerrajeros tenemos
            dentro de nuestro inventario las llaves con chip programables. Esto
            nos permite hacer una nueva llave de vehículo que funcione para el
            sistema de cualquier vehículo.
          </Text>

          <Text>
            Por lo general, este proceso puede tardar varios minutos. Esto se
            debe a que debemos tomar un buen tiempo para para encontrar la llave
            en nuestro inventario. Una vez que tengamos la llave, debemos
            programarla. Debido a que hay un esfuerzo adicional para esta tarea,
            suele ser un poco más costosa que el duplicado convencional de las
            llaves de casa. Después de que tengamos la llave, pasamos a
            programarla y luego a cortarla, dos pasos importantes y necesarios
            para duplicar las llaves con chip del vehículo.
          </Text>

          <Text>
            No dudes en llamarnos, contamos con un servicio de alta calidad.
          </Text>
        </section>
      </DefaultContainer>

      <Contact
        id={'contacto'}
        business={'Locksmith'}
        businessName={'Cerrajería'}
        priceRange={'$20.000 - $400.000'}
      />
    </Layout>
  )
}

const H2: React.FC<{ children: React.ReactNode | string }> = props => {
  return (
    <h2 className={'font-semibold text-3xl mt-6 mb-2'}>{props.children}</h2>
  )
}

const H3: React.FC<{ children: React.ReactNode | string }> = props => {
  return <h3 className={'font-semibold text-xl mt-6 mb-2'}>{props.children}</h3>
}

export default Locksmith
